import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Elements
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default class GallerySection extends Component {

    render() {
        let photos = this.props.gallery.general.slice();
        if(this.props.as_section !== undefined) {
            photos.length = Math.min(photos.length, 3);
        }
        if(photos.length === 0) {
            return <></>
        }
        return (
        <div className="space-medium">
            <Container>
                <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <div className="section-title">
                        {this.props.as_section?
                            <h2>Gallery</h2>
                        :
                            <h1>Gallery</h1>
                        }
                        </div>
                    </Col>
                </Row>
                <Row>
                {photos.map((photo, index) => (
                    <Col lg={4} md={4} sm={6} xs={12} key={index}>
                        <div className="wow zoomIn" data-wow-duration="1.3s" data-wow-delay="0.3s">
                            <div className="polaroid">
                                <img src={photo} alt="gallery" className="img-zoom" style={{borderRadius:'10px'}}/>
                            </div>
                        </div>
                    </Col>
                ))}
                </Row>
                {this.props.as_section?
                <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <div className="mt40 text-center">
                            <Link to="/gallery"><div className="btn btn-primary btn-lg">view gallery</div></Link>
                        </div>
                    </Col>
                </Row>
                :null}
            </Container>
        </div>
        );
    }
}