import React, { Component } from 'react';

// Elements
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default class Header extends Component {

    render() {
        if(this.props.treatments.length === 0) {
            return <></>
        }
        return (
        <div className="space-medium" id='treatments'>
            <Container>
                <Row>
                    <Col lg={12} md={12} sm={12} xs={12}>
                        <div className="section-title">
                            <h2>Our Services</h2>
                        </div>
                    </Col>
                </Row>
                <Row>
                    {this.props.treatments.map(service =>(
                    <Col lg={4} md={6} sm={6} xs={12} key={service.id}>
                        <a href={service.url?service.url:`/treatments/${service.id}`}>
                        <div className="service-block wow fadeInUp" data-wow-duration="1.3s" data-wow-delay="0.3s"
                            style={{background:this.props.theme.overrides.card_background_colour}}>
                            <div className="service-icon" >
                                {service.icon?
                                 <i className={service.icon}></i>
                                 :<img alt='treatment' src={service.icon_path} className='treatment icon'/>}
                            </div>
                            <div>
                                <h3>{service.name}</h3>
                                <p>{service.about_short}</p>
                            </div>
                        </div>
                        </a>
                    </Col>))}
                </Row>
            </Container>
        </div>
        );
    }
}