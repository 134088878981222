import React, { Component } from 'react';

// Elements
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default class Features extends Component {

    render() {
        let features = this.props.features;
        if(features === undefined) {
            return <></>
        }
        return (
        <div className="about-bg-block ">
            <Container>
                <Row>
                    <Col lg={6} md={6} sm={12} xs={12}>
                        <div className="feature-block">
                            <h1 className="feature-title mb60">{features.title}</h1>
                            <Row>
                                {features.features.map(feature => (
                                    <Col lg={6} md={6} sm={6} xs={12}>
                                        <div className="feature-content">
                                            <h3 className="feature-title">{feature.title}</h3>
                                            <p className="feature-text">{feature.text}</p>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}>
                        <center>
                            <img src={features.image} alt="featured image" className='feature-image'/>
                            {features.link !== undefined?
                            <>
                                <br/>
                                <a href={features.link.url} className='feature-text feature-link'>{features.link.text}</a>
                            </>:null}
                        </center>
                    </Col>
                </Row>
            </Container>
        </div>
        );
    }
}